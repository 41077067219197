import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatLegacyTable as MatTable } from "@angular/material/legacy-table";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { filter, take, tap } from "rxjs/operators";

import { AppDialogModule } from "src/app/shared/components/app-dialog/app-dialog.component";
import { AppSpinnerModule } from "src/app/shared/modules/app-spinner.module";
import { CapabilityParams } from "src/app/shared/directives/capabilities/capabilities-params.model";
import { CapabilitiesService } from "src/app/shared/services/capabilities/capabilities.service";
import { DocumentDisplayerService } from "src/app/shared/services/document-displayer.service";
import { DocumentService } from "src/app/shared/services/document.service";
import { environment } from "src/environments/environment"
import { Order } from "src/app/shared/models/order";
import { OrderRuleAudit } from 'src/app/shared/models/order-rule-audit.model';

import { Document } from "../../../shared/models/document.models";
import { OrderDetailStore } from "../../+state/order-detail.store";
import { ActivatedRoute, Router } from "@angular/router";
import { DocumentEditModalComponent } from "../document-edit-modal/document-edit-modal.component";

@UntilDestroy()
@Component({
  selector: "app-documents-list",
  templateUrl: "./documents-list.component.html",
  styleUrls: ["./documents-list.component.scss"],
})
export class DocumentsListComponent implements OnInit, OnChanges {
  @ViewChild("table") table: MatTable<Document>;

  @Output() loadDocumentsCalled = new EventEmitter<boolean>();
  @Input() documents: Document[];
  @Input() isOrderLocked: boolean;
  @Input() isIEOrEdge: boolean;
  @Input() order: Order;
  @Input() ruleViolations: OrderRuleAudit;

  isOrderLocked$: Observable<boolean>;
  removeText: string;

  public id: number;

  constructor(
    private readonly modalService: NgbModal,
    private readonly route: ActivatedRoute,
    private readonly dialog: AppDialogModule,
    private readonly toastr: ToastrService,
    private readonly spinner: AppSpinnerModule,
    private readonly documentService: DocumentService,
    private documentDisplayer: DocumentDisplayerService,
    private capabilitiesService: CapabilitiesService,
    private store: OrderDetailStore,
    private readonly router: Router,
  ) {}

  displayedColumns: string[];
  isInvalidDragEvent = false;

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.isOrderLocked$ = this.store.isOrderLocked$;
    this.orderTableColumns();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.documents && !changes.documents.isFirstChange()) {
      this.setDocumentStatus(this.documents);
    }
  }

  orderTableColumns() {
    this.displayedColumns = [
      "name"
    ];

    this.store.documentCapabilities$
      .pipe(
        untilDestroyed(this),
        filter(() => !this.displayedColumns.some((c) => c === "actions"))
      )
      .subscribe((documentCapabilities) => {
        if (
          documentCapabilities?.some((dc) =>
            dc.capabilities.some(
              (c) => c === "CanEditDocument" || c === "CanRemoveDocument"
            )
          )
        ) {
          this.displayedColumns.push("actions");
        }
      });
  }

  private loadDocuments() {
    this.loadDocumentsCalled.emit(true);
  }

  onDeleteDocument(documentId: number, documentStatusCode: string): void {
    if(documentStatusCode === "PARTSIGNED" || documentStatusCode === "SIGNED") {
      this.removeText = "Void";
    }
    else {
      this.removeText = "Delete";
    }

    this.dialog
      .show({
        title: `${this.removeText} Document`,
        message: "Are you sure you want to remove this document?",
        noButtonText: "Cancel",
        yesButtonText: `${this.removeText} Document`,
      })
      .result.then((response) => {
        if (response === "yes") {
          this.spinner.show();

          this.documentService
            .deleteDocument(documentId)
            .subscribe(
              () => {
                this.toastr.success("Document removed successfully.");
                this.loadDocuments();
              },
              () => {
                this.toastr.error(
                  "Sorry, an error occurred while removing your document"
                );
              }
            )
            .add(() => this.spinner.hide());
        }
      });
  }

  displayPackageDocument(
    packageDocumentId: number,
    isSmartDoc: boolean,
    documentName: string
  ) {
    const capabilities: CapabilityParams[] = [
      {
        capabilityString: "CanReadDownloadMyDocument",
        type: "document",
        id: packageDocumentId,
      },
      {
        capabilityString: "CanReadNBTHExecutedClosingPackage",
        type: "document",
        id: packageDocumentId,
      },
    ];
    this.capabilitiesService
      .userHasAnyCapability(capabilities)
      .pipe(
        tap((hasCapability) => {
          if (hasCapability) {
            const url = `${environment.apiUri}/v1/documents/${packageDocumentId}/download`;
            let format = "";

            if (isSmartDoc) {
              format = "application/xml";
            } else {
              format = "application/pdf";
            }

            this.documentDisplayer.displayDocument(url, format, documentName);
          }
        }),
        take(1)
      )
      .subscribe();
  }

  setDocumentStatus(documents: Document[]) {
    var violations = this.ruleViolations.documentRules;
    documents.forEach(function (doc: Document) {
      if (doc.status === 'SIGNABLE') {
        if (violations?.some(d => d.packageDocumentId === doc.id)) {
          doc.status = 'Needs Attention';
        }
        else {
          doc.status = 'Ready';
        }
      }
    })
    return documents;
  }

  openDocumentEditor(
    documentList: Document[],
    documentId: number,
    isSmartDoc: boolean
  ) {
    if(!isSmartDoc) {
      this.router.navigate(["/digi-docs/" + this.id], { state: {docId: documentId, docList: documentList }});
    }
    else {
      this.editDocumentModal(documentId);
    }
  }

  editDocumentModal(documentId: number) {
    const modal = this.modalService.open(DocumentEditModalComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });

    const activeDocument = this.documents.find((d) => d.id === documentId);

    modal.componentInstance.packageDocumentId = documentId;
    modal.componentInstance.closingType = this.order.productType;
    modal.componentInstance.isPreSignOrder = this.order.isPreSign;
    modal.componentInstance.isPresignEligible =
      activeDocument?.isPresignEligible;

    modal.result.then((result) => {
      if (result !== "cancel") {
        this.loadDocuments();
      }
    });
  }
}
