import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Output } from "@angular/core";
import { MatSortModule } from "@angular/material/sort";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UntilDestroy } from "@ngneat/until-destroy";

import { Observable } from "rxjs";

import { DigiDocsStore } from "src/app/digitization-tool/+state/digi-docs.store";
import { PDFThumbnail } from "src/app/shared/services/pdfjs/pdfjs.service";
import { DocumentStatus } from "../../../shared/enums/documentStatus";

import { DocumentEditorListDropDownButtonComponent } from "../document-editor-list-dropdown-button/document-editor-list-dropdown-button";
import { getAvailableActions } from "../document-editor-utilities/get-available-actions";

@UntilDestroy()
@Component({
  standalone: true,
  selector: "app-document-editor-thumbnails",
  templateUrl: "./document-editor-thumbnails.component.html",
  styleUrls: ["./document-editor-thumbnails.component.scss"],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    DocumentEditorListDropDownButtonComponent,
    NgbModule,
  ],
})
export class DocumentEditorThumbnailsComponent {
  pdfThumbnails$: Observable<PDFThumbnail[]>;
  showVoidedDocuments$: Observable<boolean>;
  getAvailableActions = getAvailableActions;
  documentStatuses = DocumentStatus;

  @Output() displaySelectedDocumentId = new EventEmitter<number>();
  @Output() deleteByDocumentId = new EventEmitter<number>();
  @Output() downloadByDocumentId = new EventEmitter<{
    docId: number;
    docName: string;
  }>();

  constructor(private readonly componentStore: DigiDocsStore) {
    this.pdfThumbnails$ = this.componentStore.pdfThumbnails$;
    this.showVoidedDocuments$ = this.componentStore.showVoidedDocuments$;
  }

  handleDeleteByDocumentId(docId: number) {
    this.deleteByDocumentId.emit(docId);
  }

  handleDownloadByDocumentId(docId: number, docName: string) {
    this.downloadByDocumentId.emit({ docId, docName });
  }

  handleEditByDocumentId(
    documentId: number,
    isVoid: boolean = false,
    canEdit: boolean = true
  ) {
    if (!isVoid && canEdit) {
      this.displaySelectedDocumentId.emit(documentId);
    }
  }

  handleThumbnailLoad(event: Event): void {
    const documentThumbnail = event.target as HTMLElement;
    const documentThumbnailLoading = (
      documentThumbnail.parentElement?.parentElement as HTMLElement
    ).previousElementSibling as HTMLElement;

    documentThumbnailLoading.classList.add("hide");
    documentThumbnail.classList.remove("hide");
  }
}
