import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MSAL_INSTANCE } from "@azure/msal-angular";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { DigiDocsStore } from '../../../+state/digi-docs.store';
import { DigitizationParameter } from '../../../models/digitization-parameter.model';
import { Attribute, Document } from "src/app/shared/models/document.models";
import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalIdToken } from "src/app/shared/models/account.models";
import { Order } from "src/app/shared/models/order";
import { OrdersService } from "src/app/shared/services/orders.service";
import { DocumentRule } from "src/app/shared/enums/documentRule";
import { ToastrService } from "ngx-toastr";

interface DocumentEditFormState
{
  documentName: any,
  documentType: any,
  actionType: any,
  paperSize: any
}

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'app-document-edit-form',
  templateUrl: './document-edit-form.component.html',
  styleUrls: ['./document-edit-form.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ]
})
export class DocumentEditFormComponent implements OnInit {
  @Output() exitDocumentEditor = new EventEmitter<void>();
  @Output() updateReviewedByAttribute = new EventEmitter<Document>();
  @Input() isOrderLocked: boolean;
  @Input() order: Order;

  docAttributes: Attribute[] = [];
  currentUserName: any;
  docId: any;
  docName: any;
  pageType: any;
  sequenceNumber: any;
  requiresReview = false;
  totalPages: any;
  docType: any;
  isDigitized: any;
  isDocPresign: any;
  ruleViolations: any;
  reviewedBy: any;

  documentForm: UntypedFormGroup;

  digitizationParameters$: Observable<DigitizationParameter>;

  private formState: DocumentEditFormState;

  constructor(
    private readonly componentStore: DigiDocsStore,
    private readonly ordersService: OrdersService,
    private readonly toastr: ToastrService,
    @Inject(MSAL_INSTANCE) private readonly msalInstance: IPublicClientApplication
  ) { }

  ngOnInit(): void {
    this.digitizationParameters$ = this.componentStore.digitizationParameters$;
    this.initForm();
    this.updateFormWithDigitizationParameters().subscribe();
    this.watchFormAndUpdateState().subscribe();
    this.getRuleAudit();
  }

  handleExitDocumentEditor() {
    this.exitDocumentEditor.emit();
  }

  initForm() {
    this.documentForm = new UntypedFormGroup({
      documentName: new UntypedFormControl({value: null, disabled: this.isOrderLocked}),
      documentType: new UntypedFormControl({value: null, disabled: this.isOrderLocked}),
      actionType: new UntypedFormControl({value: null, disabled: true}),
      paperSize: new UntypedFormControl({value: null, disabled: this.isOrderLocked})
    });
  }

  setControlEnabledState(name: string, isEnabled: boolean): void {
    const ctrl = this.control(name);
    if (ctrl === null || ctrl === undefined) {
      return;
    }
    if (isEnabled)
    {
      ctrl.enable({onlySelf: true, emitEvent: false});
    }
    else
    {
      ctrl.disable({onlySelf: true, emitEvent: false});
    }
  }

  setDisabled(name: string): void {
    this.setControlEnabledState(name, false);
  }

  setEnabled(name: string): void {
    this.setControlEnabledState(name, true);
  }

  control(name: string): AbstractControl | null {
    return this.documentForm.get(name);
  }

  getRuleAudit() {
    this.ordersService
      .getRuleAudit(this.order.id)
      .pipe(
        tap((ruleViolations) => {
          this.ruleViolations = ruleViolations;
          this.checkIfNeedsReviewed();
        })
      )
      .subscribe();
  }

  updateFormWithDigitizationParameters(): Observable<DigitizationParameter> {
    return this.digitizationParameters$.pipe(
      filter(digitizationParameters => !!digitizationParameters),
      tap(digitizationParameters => {
        this.docId = digitizationParameters.mySelectedDocument?.id ?? 0;
        this.docName = digitizationParameters.documentName;
        this.pageType = digitizationParameters.mySelectedDocument?.pageType;
        this.sequenceNumber = digitizationParameters.mySelectedDocument?.sequenceNumber ?? 1;
        this.totalPages = digitizationParameters.mySelectedDocument?.totalPages;
        this.docType = digitizationParameters.mySelectedDocument?.type;
        this.isDocPresign = digitizationParameters.mySelectedDocument?.isDocPreSign;
        this.isDigitized = digitizationParameters.mySelectedDocument?.isDigitized;

        if(digitizationParameters.mySelectedDocument?.attributes) {
          this.docAttributes = digitizationParameters.mySelectedDocument?.attributes;

          if(digitizationParameters.mySelectedDocument?.attributes.some(a => a.key === "REVIEWEDBY")) {
            this.reviewedBy = digitizationParameters.mySelectedDocument?.attributes.find(a => a.key === "REVIEWEDBY")?.value;
          }
        }

        this.formState = {
          documentType: digitizationParameters.selectedDocumentType,
          documentName: digitizationParameters.documentName,
          actionType: digitizationParameters.actionType,
          paperSize: digitizationParameters.selectedPaperSize
        };

        this.documentForm.get('documentType')?.setValue(this.formState.documentType);
        this.documentForm.get('documentName')?.setValue(this.formState.documentName);
        this.documentForm.get('actionType')?.setValue(this.formState.actionType);
        this.documentForm.get('paperSize')?.setValue(this.formState.paperSize);

        if ((digitizationParameters.mySelectedDocument?.id || 0) > 0 &&
           digitizationParameters.mySelectedDocument?.isDigitized)
        {
          this.setDisabled('paperSize');
        }
        else
        {
          this.setEnabled('paperSize');
        }
      }),
      untilDestroyed(this)
    );
  }

  markAsReviewed(): void {
    const users = this.msalInstance.getAllAccounts();

    const userToken = users[0].idTokenClaims as MsalIdToken;

    this.currentUserName = `${userToken.given_name} ${userToken.family_name}`;
    const attribute = new Attribute();
    attribute.key = "REVIEWEDBY";
    attribute.value = this.currentUserName;
    this.docAttributes.push(attribute);
    const document: Document = {
      attributes: this.docAttributes,
      id: this.docId,
      isPaper: false,
      name: this.docName,
      pageType: this.pageType,
      sequenceNumber: this.sequenceNumber,
      totalPages: this.totalPages,
      type: this.docType,
      isDocPreSign: this.isDocPresign,
      isDigitized: this.isDigitized
    };

    this.updateReviewedByAttribute.emit(document);

    this.checkIfNeedsReviewed();

    if(this.requiresReview === true) {
      this.toastr.error("Marked as reviewed failed.");
    }
    else {
      this.toastr.success("Successfully marked as reviewed.");
      this.reviewedBy = this.currentUserName;
    }
  }

  checkIfNeedsReviewed() {
    this.requiresReview = false;
    if(this.ruleViolations.documentRules.some(rv => rv.packageDocumentId === this.docId 
        && rv.ruleName === DocumentRule.RequiresReview) 
        && this.docAttributes.every(a => a.key !== "REVIEWEDBY")) {
      this.requiresReview = true;
    }
  }

  watchFormAndUpdateState(): Observable<any> {
    return this.documentForm.valueChanges.pipe(
      debounceTime(300),
      tap(value => {
        this.formState = {
          ...this.formState,
          ...value
        };

        const digitizationUpdates: Partial<DigitizationParameter> = {
          selectedDocumentType: this.formState.documentType,
          documentName: this.formState.documentName,
          actionType: this.formState.actionType,
          selectedPaperSize: this.formState.paperSize
        };
        this.componentStore.updateDigitizationParameters(digitizationUpdates as any);
      }),
      untilDestroyed(this)
    );
  }
}
